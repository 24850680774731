import { FaFileCode } from "react-icons/fa6";
import styles from "./VulnerbilitesList.module.css";
import { vulReport } from "../../../../utils/reportDataTypes";
import { getSeverityBorderColor, getSeverityColor } from "../../../../utils/textformUtils";
import { Link } from "react-router-dom";

interface IVulnerabilitiesList {
  totalReports: vulReport[];
}

export const VulnerabilitiesList: React.FC<IVulnerabilitiesList> = ({
  totalReports,
}) => {
  return (
    <>
      <h3>Vulnerabilities reported in past audits</h3>
      <ul className={styles.vulReportList}>
        <li className={styles.stickyRow}>
          <div className={styles.vulLevelHeader}> Severity </div>
          <div className={styles.vulInfo}> Title </div>
          <div className={styles.vulInfo}> Location </div>
          <div className={styles.linkIcon}> Link </div>
        </li>
        {totalReports.map((audit, i) => {
          return (
            <li
              key={i}
              style={
                i !== totalReports.length - 1
                  ? {
                      borderBottom:
                        "1px solid var(--ProtocolPage-border-color)",
                      display: "flex",
                    }
                  : { display: "flex" }
              }
            >
              <div
                style={{
                  background: getSeverityColor(audit.severity),
                  borderColor: getSeverityBorderColor(audit.severity),
                }}
                className={styles.vulLevel}
              >
                {audit.severity}
              </div>
              <div className={styles.vulInfo}>
                {audit.issueType}
              </div>
              <div className={styles.vulInfo}>{audit.file}</div>
              <div className={styles.linkIcon}>
                {" "}
                <Link to={`/vulnerability?name=${audit.link}`}>
                  View Report <FaFileCode />
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
