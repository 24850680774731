import styles from "./ProtocolList.module.css";
import { protocolPreview } from "../../../utils/reportDataTypes";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import { Link } from "react-router-dom";
import { sendRequest } from "../../../utils/apiRequest";
import { publishReportURL } from "../../../api";

export enum protocolState {
    Created,
    Underreview,
    Published
}
interface IProtocolList {
  isAdmin?: boolean;
  state: protocolState;
  allprotocols: protocolPreview[];
  confirmPublish: number;
  confirmDelete: number;
  setConfirmPublish: (confirmPublish: number) => void;
  setConfirmDelete: (confirmDelete: number) => void;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const ProtocolList: React.FC<IProtocolList> = ({
  isAdmin,
  state,
  allprotocols,
  confirmPublish,
  confirmDelete,
  setConfirmPublish,
  setConfirmDelete,
  setCurrentProtocolID,
}) => {
  const publishReport = async (id: number) => {
    if (id !== confirmPublish) {
      setConfirmPublish(id);
      return;
    }
    await sendRequest(
      publishReportURL,
      "Something went wrong while publish!",
      "Report successfully published",
      id,
      undefined
    );
  };

  const deleteReport = async (id: number) => {
    if (id !== confirmPublish) {
      setConfirmDelete(id);
      return;
    }
    //Send request
  };
  return (
    <>
      {allprotocols.map((protocol) => {
        return (
          <li className={styles.row} key={protocol.rid}>
            <h4 className={styles.IDtext}>{protocol.rid}</h4>
            <img className={styles.logo} src={protocol.logoURL} alt="" />
            <p className={styles.text}>{protocol.name}</p>
            <p
              className={styles.text}
              style={state === protocolState.Created ? { color: "var(--font-color)" } : state === protocolState.Underreview ? { color: "rgba(255, 159, 64, 1)" } : { color: "#4bc0c0" }}
            >
              {state === protocolState.Created ? <>Created</> : state === protocolState.Underreview ? <>Under Review</> : <>Published</> }
            </p>
            {isAdmin ? (
              <button
                className={`${inputControlStyles.button} ${
                  confirmPublish !== protocol.rid
                    ? inputControlStyles["button--tertiary"]
                    : inputControlStyles["button--primary"]
                } ${inputControlStyles["button--small"]}`}
                onClick={() => publishReport(protocol.rid)}
              >
                {confirmPublish === protocol.rid ? "Confirm" : "Publish"}
              </button>
            ) : (
              ""
            )}
            <Link
              className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
              to={`/protocol/${protocol.name}?id=${protocol.rid}${state === protocolState.Published ? "" : `&underReview=${state === protocolState.Created ? 2 : 1}`}`}
              target="_blank"
              onClick={() => setCurrentProtocolID(protocol.rid)}
            >
              View
            </Link>
            <Link
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
              to={`/publish/protocol?id=${protocol.rid}&underReview=${state === protocolState.Created ? 2 : 1}`}
            >
              {" "}
              Edit{" "}
            </Link>
            {isAdmin ? (
              <button
                className={`${inputControlStyles.button} ${inputControlStyles["button--delete"]} ${inputControlStyles["button--small"]}`}
                onClick={() => deleteReport(protocol.rid)}
              >
                {confirmDelete === protocol.rid ? "Confirm" : "X"}
              </button>
            ) : (
              ""
            )}
          </li>
        );
      })}{" "}
    </>
  );
};

export default ProtocolList;
