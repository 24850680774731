import React, { useRef, useState, useEffect} from "react";
import AdvancedShowMoreButton from "../../PublishPageComponents/AdvancedShowMoreButton";
import styles from "./Collapsible.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface IProps {
  open?: boolean;
  title: string;
  progress?: number[];
  children: JSX.Element;
}

const Collapsible: React.FC<IProps> = ({ open, children, title, progress }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<string>('0');
  const ref = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const isDarkTheme = useTheme();

  useEffect(() => {
    if (ref.current) {
      // Create ResizeObserver to watch for content size changes
      observerRef.current = new ResizeObserver((entries) => {
        if (isOpen && entries[0]) {
          setHeight(`${entries[0].target.scrollHeight}px`);
        }
      });

      // Start observing
      observerRef.current.observe(ref.current);
    }

    // Cleanup
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isOpen, children]); // Add children as dependency

  useEffect(() => {
    if (ref.current) {
      if (isOpen) {
        setHeight(`${ref.current.scrollHeight}px`);
      } else {
        setHeight('0');
      }
    }
  }, [isOpen]);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div 
      className={styles.collapsible}
      style={{
        backgroundColor: isDarkTheme ? 'rgba(255, 255, 255, 0.05)' : 'var(--container-bg)',
        borderColor: isDarkTheme ? 'rgba(255, 255, 255, 0.1)' : 'var(--border-color)'
      }}
    >
      <div 
        className={styles.collapsible__header}
        style={{
          borderBottomColor: isDarkTheme ? 'var(--background-color)' : 'var(--border-color)'
        }}
      >
        <AdvancedShowMoreButton
          text={title}
          progress={progress}
          showMore={isOpen}
          onClick={handleFilterOpening}
        />
      </div>
      <div
        className={styles.collapsible__content}
        style={{
          height: height,
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
        }}
      >
        <div className={styles.collapsible__inner} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
