import { FaArrowDown, FaRegCircle } from "react-icons/fa";
import styles from "./AdvancedShowMoreButton.module.css";
import { FaRegCircleDot } from "react-icons/fa6";
import { useTheme } from "../../../hooks/useTheme";

interface IShowMoreButton {
  text: string;
  onClick: () => void;
  showMore?: boolean;
  progress?: number[];
}

const ShowMoreButton: React.FC<IShowMoreButton> = ({
  text,
  onClick,
  showMore,
  progress,
}) => {
  const isDarkTheme = useTheme();
  
  return (
    <div
      className={`${styles["show-more"]} ${
        !showMore
          ? styles["show-more--rounded"]
          : styles["show-more--rounded-top"]
      }`}
      onClick={onClick}
      style={{
        borderColor: !isDarkTheme ? 'var(--border-color)' : 'transparent',
        backgroundColor: isDarkTheme ? 'var(--background-color)' : 'var(--container-bg)'
      }}
    >
      {!showMore ? (
        <FaRegCircle
          className={styles["show-more__icon"]}
          style={{ fontSize: "16px" }}
        />
      ) : (
        <FaRegCircleDot
          className={`${styles["show-more__icon"]} ${styles["show-more__icon--active"]}`}
          style={{ fontSize: "16px" }}
        />
      )}
      <span className={styles["show-more__title"]}>{text}</span>
      {progress ?
              <div className={styles["show-more__progress"]}>
              <p className={styles["show-more__progress-text"]}>
                Completed {progress[0]}/{progress[1]}
              </p>
            </div>
      :<></>}

      <FaArrowDown
        className={styles["show-more__icon"]}
        style={{
          fontSize: "16px",
          transform: showMore ? "rotate(180deg)" : "none",
          transition: "transform 0.2s ease-in-out",
        }}
      />
    </div>
  );
};

export default ShowMoreButton;
