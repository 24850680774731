import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { darkTheme } from "../../../../../utils/theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface IBarChart {
  labels: string[];
  AmountOfHighVulPerFile: number[];
  AmountOfMediumVulPerFile: number[];
  currentTheme?: typeof darkTheme;
}

export const BarChart: React.FC<IBarChart> = ({
  labels,
  AmountOfHighVulPerFile,
  AmountOfMediumVulPerFile,
  currentTheme,
}) => {
  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 1,
        color: "white",
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: currentTheme?.text,
        },
      },
      title: {
        display: true,
        text: "Code Locations",
        color: currentTheme?.text,
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: "High",
        data: AmountOfHighVulPerFile,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
      },
      {
        label: "Medium",
        data: AmountOfMediumVulPerFile,
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
      },
    ],
  };
  return <Bar style={{height:`20rem`}} options={options} data={data} />;
};
