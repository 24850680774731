import styles from "./WalletConnectStep.module.css";
import stepStyles from "../OnboardingStep.module.css";
import { useTheme } from "../../../hooks/useTheme";
import inputControlStyles from "../../../styles/common/inputControls.module.css";

interface IWalletConnectStep {
  handleWalletConnect: () => void;
  handleWalletStepCompleted: () => void;
  wallet: string;
}

const WalletConnectStep: React.FC<IWalletConnectStep> = ({
  wallet,
  handleWalletConnect,
  handleWalletStepCompleted,
}) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`${styles["wallet-connect-step"]} ${stepStyles["onboarding-step"]}`}
    >
      <h3 className={styles["wallet-connect-step__title"]}>Connect Wallet</h3>
      <div
        className={`${styles["wallet-connect-step__description"]} ${
          isDarkTheme
            ? styles["wallet-connect-step__description--dark"]
            : styles["wallet-connect-step__description--light"]
        }`}
      >
        <p>
          Use Metamask to connect your wallet. You will have to sign a login
          message.
        </p>
      </div>

      <div className={styles["wallet-connect-step__buttons-container"]}>
      <button
        className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
        onClick={handleWalletConnect}
      >
        Connect Wallet
      </button>
      <button
        className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
        onClick={handleWalletStepCompleted}
        disabled={wallet.length < 2}
      >
        Next
      </button>
      </div>
    </div>
  );
};

export default WalletConnectStep;
