import styles from "./ProtocolPage.module.css";
import InfoHeader from "../../components/ProtocolPageComponents/InfoHeader";
import SideMenu from "../../components/ProtocolPageComponents/SideMenu";
import SecInfra from "../../components/ProtocolPageComponents/SecInfra";
import Pastaudits from "../../components/ProtocolPageComponents/PastAudits";
import CodeComplexity from "../../components/ProtocolPageComponents/CodeComplexity";
import Centralization from "../../components/ProtocolPageComponents/Centralization";
import CrossProtocolExposure from "../../components/ProtocolPageComponents/CrossProtocolExposure";
import Recomendations from "../../components/ProtocolPageComponents/Recomendations";

import { useEffect, useRef, useState } from "react";
import Disclaimer from "../../components/ProtocolPageComponents/Disclaimer";
import { darkTheme } from "../../utils/theme";
import {
  protocolData,
  auditCompanies,
  auditCompaniesType,
} from "../../utils/reportDataTypes";
import {
  getReportDataURL,
  getReportUnderreviewDataURL,
  getReportUnderreviewDataAnyURL,
  getReportCreatedURL,
} from "../../api";
import { sendRequest } from "../../utils/apiRequest";
import { userData } from "../../utils/severRequestTypes";
import { useSearchParams } from "react-router-dom";
import { useDataContext } from "../../utils/dataProvider";
import ErrorContainer from "../../components/Reusable/ErrorContainer";

interface IProtocolPage {
  currentTheme?: typeof darkTheme;
  currentProtocolID?: number;
  user?: userData | null;
}

const ProtocolPage: React.FC<IProtocolPage> = ({
  currentProtocolID,
  currentTheme,
  user,
}) => {
  const secInfraRef = useRef(null);
  const PastauditsRef = useRef(null);
  const CodeComplexityRef = useRef(null);
  const CentralizationRef = useRef(null);
  const CrossProtocolExposureRef = useRef(null);
  const RecomendationsRef = useRef(null);

  const [queryParameters] = useSearchParams();
  const [protocol, setProtocol] = useState<protocolData | null>(null);
  const { allAuditCompanies } = useDataContext();
  const [auditCompanies, setAuditCompanies] = useState<auditCompanies[]>([
    {
      name: "",
      link: "",
      logoURL: "",
      type: auditCompaniesType.ContestPlatform,
      id: 0,
    },
  ]);
  var URL = getReportDataURL;
  //This is to some degree cursed, could be better
  const paramProtocolId = Number.parseInt(queryParameters.get("id") ?? "-1");
  const isUnderReview = Number.parseInt(
    queryParameters.get("underReview") ?? "-1"
  );
  if (isUnderReview === 1) {
    URL = user?.isAdmin
      ? getReportUnderreviewDataAnyURL
      : getReportUnderreviewDataURL;
  }
  if (isUnderReview === 2) {
    URL = user?.isAdmin
      ? getReportCreatedURL
      : getReportCreatedURL;
  }
  if (paramProtocolId !== -1) {
    currentProtocolID = paramProtocolId;
  }
  useEffect(() => {
    const fetchProtocolReport = async () => {
      await sendRequest(
        URL,
        "Error while getting report",
        undefined,
        currentProtocolID,
        setProtocol
      );
    };

    if (user) fetchProtocolReport();
  }, [currentProtocolID, user, URL]);

  useEffect(() => {
    if (protocol !== null) {
      if (protocol.secInfra.auditCompanyIDs.length > 0) {
        const filteredObjects = allAuditCompanies.filter((obj) =>
          protocol.secInfra.auditCompanyIDs.includes(obj.id)
        );
        setAuditCompanies(filteredObjects);
      }
    }
  }, [protocol, allAuditCompanies]);

  if (!user) {
    return <ErrorContainer content={<>Please Login to view Protocol Data</>} />;
  }
  if (protocol == null) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <SideMenu
          refs={[
            secInfraRef,
            PastauditsRef,
            CodeComplexityRef,
            CentralizationRef,
            CrossProtocolExposureRef,
            RecomendationsRef,
          ]}
        />
        <div className={styles.protocolContent}>
          <InfoHeader protocolData={protocol} />
          <SecInfra
            protocolData={protocol}
            ref={secInfraRef}
            auditCompanies={auditCompanies}
          />
          <Pastaudits
            currentTheme={currentTheme}
            codeData={protocol.codeComplexity}
            pastAudits={protocol.pastAudits}
            ref={PastauditsRef}
            auditCompanies={auditCompanies}
          />
          <CodeComplexity
            currentTheme={currentTheme}
            protocolData={protocol}
            ref={CodeComplexityRef}
          />
          <Centralization protocolData={protocol} ref={CentralizationRef} />
          <CrossProtocolExposure
            crossProtocolExposure={protocol.crossProtocolExposure}
            ref={CrossProtocolExposureRef}
          />
          <Recomendations
            recommendations={protocol.recommendations}
            ref={RecomendationsRef}
          />
          <Disclaimer />
        </div>
      </div>
    </div>
  );
};

export default ProtocolPage;
