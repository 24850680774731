import styles from "./PastAudits.module.css";
import {
  auditCompanies,
  codeComplexity,
  pastAudits,
  vulReport,
} from "../../../utils/reportDataTypes";
import { useEffect, useState } from "react";
import { BarChart } from "./Charts/BarChart";
import { DoughnutChart } from "./Charts/DoughnutChart";
import React from "react";
import { darkTheme } from "../../../utils/theme";
import { formatDate } from "../../../utils/textformUtils";
import { AuditReportsList } from "./AuditReportsList";
import { VulnerabilitiesList } from "./VulnerabilitesList";
import MetricContainer from "../../Reusable/MetricContainer";
import { FaCircleCheck } from "react-icons/fa6";
import ErrorContainer from "../../Reusable/ErrorContainer";
import Collapsible from "../../Reusable/Collapsible";

interface IPastaudits {
  pastAudits: pastAudits;
  codeData: codeComplexity;
  auditCompanies: auditCompanies[];
  currentTheme?: typeof darkTheme;
}

const Pastaudits = React.forwardRef<HTMLDivElement, IPastaudits>(
  ({ codeData, pastAudits, currentTheme, auditCompanies }, ref) => {
    const [amountOfHighVulPerFile, setAmountOfHighVulPerFile] = useState<
      number[]
    >([]);
    const [amountOfMediumVulPerFile, setAmountOfMediumVulPerFile] = useState<
      number[]
    >([]);
    const [highIssues, setHighIssues] = useState(0);
    const [mediumIssues, setMediumIssues] = useState(0);
    const [lowIssues, setLowIssues] = useState(0);
    const [informationalIssues, setInformationalIssues] = useState(0);
    const [filesAffected, setFilesAffected] = useState<string[]>([]);
    const totalReports: vulReport[] = pastAudits.audits.flatMap(
      (audit) => audit.reports,
    );
    const auditslength = pastAudits.audits.length;

    const mostRecentObject = auditslength > 0 ? pastAudits.audits.reduce((latest, current) => {
      return new Date(current.date) > new Date(latest.date) ? current : latest;
    }).date : '1970-01-01';

    useEffect(() => {
      const arr: string[] = [];

      for (let i = 0; i < totalReports.length; i++) {
        const regex = /main\/.+?\/(?<path>.+?)(?:#|$)/;
        const match = totalReports[i].file.match(regex);
        if (match && match.groups) {
          const path = match.groups.path;
          totalReports[i].file = path;
        }
        if (!arr.includes(totalReports[i].file)) {
          arr.push(totalReports[i].file);
        }
      }
      const amountOfHighVulPerFile: number[] = new Array<number>(arr.length);
      const amountOfMediumVulPerFile: number[] = new Array<number>(arr.length);
      let tmpMedIssue = 0;
      let tmpHighIssue = 0;
      let tmpLowIssue = 0;
      let tmpInfoIssue = 0;
      //This might not be the optimal solution
      for (let i = 0; i < totalReports.length; i++) {
        if (totalReports[i].severity.toLowerCase() === "medium") {
          if (amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)] > 0) {
            amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)]++;
          } else {
            amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)] = 1;
          }
          tmpMedIssue++;
        } else if (totalReports[i].severity.toLowerCase() === "high") {
          if (amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)] > 0) {
            amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)]++;
          } else {
            amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)] = 1;
          }
          tmpHighIssue++;
        }
        if (totalReports[i].severity.toLowerCase() === "low") {
          tmpLowIssue++;
        }
        if (
          totalReports[i].severity.toLowerCase() === "info" ||
          totalReports[i].severity.toLowerCase() === "low/info"
        ) {
          tmpInfoIssue++;
        }
      }

      setHighIssues(tmpHighIssue);
      setMediumIssues(tmpMedIssue);
      setLowIssues(tmpLowIssue);
      setInformationalIssues(tmpInfoIssue);

      setAmountOfHighVulPerFile(amountOfHighVulPerFile);
      setAmountOfMediumVulPerFile(amountOfMediumVulPerFile);
      setFilesAffected(arr);
    }, [pastAudits]);

    if(pastAudits.auditAmount === 0){
      return (
        <div id="pastAuditContainer" className={styles.container} ref={ref}>
          <h2>Past Audits</h2>
          <ErrorContainer 
          content={<>SCAS has found no Security Audits of this Protocol</>}/>
        </div>
      );
    }
    return (
      <>
        <div id="pastAuditContainer" className={styles.container} ref={ref}>
          <h2>Past Audits</h2>
          <div className={styles.alertContainer}>
            <div className={styles.chartContainer}>
              <DoughnutChart
                currentTheme={currentTheme}
                highIssues={highIssues}
                mediumIssues={mediumIssues}
                lowIssues={lowIssues}
                informationalIssues={informationalIssues}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={styles.InfoContainer}>
                <MetricContainer title={<>Number of Audits</>} content={<>{pastAudits.auditAmount}</>}/>
                <MetricContainer title={<>Number of Vul. Found </>} content={<>{pastAudits.reportAmount}</>}/>
                <MetricContainer title={<>Date of Last Audit </>} content={
                <>
                {auditslength > 0
                  ? formatDate(
                    mostRecentObject,
                    )
                  : ""}
                </>
                }/>
              </div>
              <AuditReportsList
                codeData={codeData}
                auditCompanies={auditCompanies}
                pastAudits={pastAudits}
              />
            </div>
          </div>
          {
          totalReports.length === 0 ?
          <div className={styles.alertContainerNoReco}>
            <div className={styles.IconNoReco}>
              <FaCircleCheck />
            </div>
            <p> SCAS has found no Vulnerabilities reported!</p>
          </div>
          :
          <>
          <Collapsible title="Show Code Locations" children={
            <BarChart
            currentTheme={currentTheme}
            labels={filesAffected}
            AmountOfHighVulPerFile={amountOfHighVulPerFile}
            AmountOfMediumVulPerFile={amountOfMediumVulPerFile}
          />
          } />
          <Collapsible title="Show all Vulnerabilities" children={
            <>{totalReports.length > 0 && (
              <div
                className={styles.vulReportContainer}
              >
                <VulnerabilitiesList totalReports={totalReports} />
              </div>
            )}</>}
          />
          </>}
        </div>
      </>
    );
  },
);

export default Pastaudits;
