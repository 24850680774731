import { useState, useEffect } from "react";

export function useTheme() {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });

  useEffect(() => {
    const handleThemeChange = () => {
      setIsDarkTheme(localStorage.getItem("theme") === "dark");
    };

    // Listen for storage events (cross-tab)
    window.addEventListener("storage", handleThemeChange);

    // Listen for custom themeChange events (same-page)
    window.addEventListener("themeChange", handleThemeChange);

    return () => {
      window.removeEventListener("storage", handleThemeChange);
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  return isDarkTheme;
}
