import styles from "./AddAuditCompany.module.css";
import {
  auditCompaniesType,
  auditCompanies,
} from "../../../utils/reportDataTypes";
import { useState } from "react";
import { addAuditCompanyURL } from "../../../api";
import { tmpAuditCompanie } from "../../../utils/templateProtocolData";
import { sendRequest } from "../../../utils/apiRequest";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../components/Reusable/FormInput";
import FormRadioInput from "../../../components/Reusable/FormRadioInput/FormRadioInput";

export const AddAuditCompany: React.FC = () => {
  const [auditCompany, setAuditCompany] =
    useState<auditCompanies>(tmpAuditCompanie);
  const navigate = useNavigate();

  const sendPublishReportDataRequest = async (request: auditCompanies) => {
    await sendRequest(
      addAuditCompanyURL,
      "Error adding auditCompany",
      "Added company!",
      request,
    );
  };

  const handelAuditCompanyNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.name = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyURLChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.link = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.logoURL = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.type =
      auditCompaniesType[e.target.value as keyof typeof auditCompaniesType];
    setAuditCompany({ ...tmpAuditCompany });
  };

  return (
    <>
      <div className={styles.container}>
        <h3>Add Audit Company</h3>
        <FormInput
          title={"Name"}
          placeholder={"Sherlock"}
          onChange={handelAuditCompanyNameChange}
        />
        <FormInput
          title={"Website Link"}
          placeholder={"https://"}
          onChange={handelAuditCompanyURLChange}
        />
        <FormInput
          title={"Logo URL (Twitter)"}
          placeholder="https://"
          onChange={handelAuditCompanyLogoChange}
        />

        <FormRadioInput
          value={auditCompany.type.toString()}
          buttons={[
            {
              label: "Contest platform",
              value: "ContestPlatform",
            },
            {
              value: "Institutional",
              label: "Institutional",
            },
            {
              value: "IndependentResearcher",
              label: "Independent Researcher",
            },
          ]}
          onChange={handelAuditCompanyRadioChange}
        />
        <div className={styles.splitContainer}>
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            onClick={() => sendPublishReportDataRequest(auditCompany)}
          >
            Save audit company
          </button>
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            onClick={() => navigate(`/publish`)}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
