import styles from "./SideMenu.module.css";
import { Link, useNavigate } from "react-router-dom";
import { protocolData } from "../../../utils/reportDataTypes";
import { ArrowLeft, ExternalLink } from 'lucide-react';
import { useTheme } from '../../../hooks/useTheme';
import { LuFileUp } from "react-icons/lu";
import { submitReportURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";

interface ISideMenu {
  protocolPreview: protocolData | null;
  totalProgressMax: number;
  totalProgressCurrent: number;
}

const SideMenuPublishTool: React.FC<ISideMenu> = ({
  totalProgressMax,
  totalProgressCurrent,
  protocolPreview,
}) => {
  const navigate = useNavigate();
  const isDarkTheme = useTheme();

  const iconColor = isDarkTheme 
    ? "rgba(255, 255, 255, 0.87)" 
    : "rgba(0, 0, 0, 0.87)";

    const publishReport = async (id: number) => {

      await sendRequest(
        submitReportURL,
        "Something went wrong while submitting!",
        "Report successfully published",
        id,
        undefined,
      );
    };
  return (
    <>
      <div className={`${styles['side-menu']} ${isDarkTheme ? styles['side-menu--dark'] : styles['side-menu--light']}`}>
        <ul className={styles['side-menu__list']}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 20px 10px 20px",
            }}
          >
            <img
              className={
                protocolPreview !== null 
                  ? styles['side-menu__logo'] 
                  : styles['side-menu__logo--temporary']
              }
              alt=""
              src={
                protocolPreview !== null
                  ? protocolPreview.logoURL
                  : "https://scauditstudio.de/wp-content/uploads/2024/04/eth.png"
              }
            />
            <b className={styles['side-menu__header-text']}>
              {protocolPreview !== null ? protocolPreview.name : "New Protocol"}
            </b>
          </div>
          <li className={styles['side-menu__row']}>
            <span className={styles['side-menu__text']}>Currently Editing</span>
          </li>
          <li className={styles['side-menu__row']}>
            <span className={styles['side-menu__text']}>Protocol ID: {protocolPreview?.rid ? protocolPreview?.rid : "N/A"}</span>
          </li>
          <li className={styles['side-menu__row']}>
            <span className={styles['side-menu__text']}>Completed: 5%</span>
          </li>
          <div
            className={styles['side-menu__button']}
            onClick={() => publishReport(protocolPreview?.rid ? protocolPreview?.rid : 0)}
          >
            <LuFileUp size={18} color={iconColor} />
            <span
            className={styles['side-menu__text']}
            >Submit for Review</span>
          </div>
          <Link
            className={styles['side-menu__button']}
            to={`/protocol/new?id=${protocolPreview === null ? "" : protocolPreview.rid}&underReview=1`}
            target="_blank"
          >
            <ExternalLink size={18} color={iconColor} />
            <span
            className={styles['side-menu__text']}
            >View Protocol</span>
          </Link>
          <li
            className={styles['side-menu__button']}
            onClick={() => navigate(`/publish`)}
          >
            <ArrowLeft size={18} color={iconColor} />
            <span className={styles['side-menu__text']}>Back</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideMenuPublishTool;
