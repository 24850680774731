import React, { createContext, useState, useContext, useEffect } from 'react';
import { sendRequest } from './apiRequest';
import { getAuditCompaniesURL } from '../api';
import { auditCompanies } from './reportDataTypes';

// Define the context type
interface DataContextType {
    allAuditCompanies: auditCompanies[];
    setAllAuditCompanies: React.Dispatch<React.SetStateAction<auditCompanies[]>>;
}

// Create the context
const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [allAuditCompanies, setAllAuditCompanies] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
          await sendRequest(
            getAuditCompaniesURL,
            "Error while getting companies",
            undefined,
            undefined,
            setAllAuditCompanies,
          );
        };
        fetchData();
      }, []);

    return (
        <DataContext.Provider value={{ allAuditCompanies, setAllAuditCompanies }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;
