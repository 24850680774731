import styles from "./CodeComplexity.module.css";
import { protocolData } from "../../../utils/reportDataTypes";
import React from "react";
import Flow from "./FlowChart";
import { RadarChart } from "./RadarChart";
import { darkTheme } from "../../../utils/theme";
import MetricContainer from "../../Reusable/MetricContainer";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import Collapsible from "../../Reusable/Collapsible";

interface ICodeComplexity {
  protocolData: protocolData;
  currentTheme?: typeof darkTheme;
}

const CodeComplexity = React.forwardRef<HTMLDivElement, ICodeComplexity>(
  ({ protocolData, currentTheme }, ref) => {
    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Code Structure</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={styles.chartContainer}>
              <RadarChart
                currentTheme={currentTheme}
                comparisonData={protocolData.codeComplexity.similarProtocols}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className={styles.InfoContainer}>
                <MetricContainer
                  title={<>Lines of Code </>}
                  content={<>{protocolData.codeComplexity.linesOfCode}</>}
                />
                <MetricContainer
                  title={<>Amount of Contracts </>}
                  content={<>{protocolData.codeComplexity.amountOfContracts}</>}
                />
                <MetricContainer
                  title={<>External Integrations </>}
                  content={
                    <>
                      {protocolData.codeComplexity.amountOfExternalIntegrations}
                    </>
                  }
                />
              </div>
              <Link
                target="_blank"
                to={protocolData.codeBaseLink}
                className={styles.codeBaseLink}
              >
                <h3>Code Summery</h3> <FaExternalLinkAlt size={12} />
              </Link>
              <p style={{ textAlign: "start", textJustify: "auto" }}>
                {protocolData.codeComplexity.description.replaceAll(
                  "0x0A",
                  "\n"
                )}
              </p>
            </div>
          </div>
          <Collapsible
            title="Show Dependency Graph"
            children={
              <div className={styles.dependecyGraphContainer}>
                <h3>Code Structure & Dependency </h3>
                <div className={styles.flowChartContainer}>
                  <Flow
                    contracts={protocolData.codeComplexity.contractMetrics}
                  />
                </div>
              </div>
            }
          />
          <Collapsible
            title="Show Codefile metrics"
            children={
              <>
                {protocolData.codeComplexity.contractMetrics.length > 0 && (
                  <div
                    className={styles.vulReportContainer}
                  >
                    <h3> Code Metrics </h3>
                    <ul className={styles.vulReportList}>
                      <li className={styles.stickyRow}>
                        <div className={styles.codeInfoFileName}>
                          {" "}
                          Contract Name{" "}
                        </div>
                        <div className={styles.codeInfo}> Interfaces </div>
                        <div className={styles.codeInfo}> Lines </div>
                        <div className={styles.codeInfo}> nSLOC </div>
                        <div className={styles.codeInfo}> Complex. Score </div>
                      </li>
                      {protocolData.codeComplexity.contractMetrics.map(
                        (contract, i) => {
                          return (
                            <li
                              key={i}
                              style={
                                i !==
                                protocolData.codeComplexity.contractMetrics
                                  .length -
                                  1
                                  ? {
                                      borderBottom:
                                        "1px solid var(--ProtocolPage-border-color)",
                                      display: "flex",
                                    }
                                  : { display: "flex" }
                              }
                            >
                              <div className={styles.codeInfoFileName}>
                                {contract.fileName}
                              </div>
                              <div className={styles.codeInfo}>
                                {contract.interfaces}
                              </div>
                              <div className={styles.codeInfo}>
                                {contract.linesOfCode}
                              </div>
                              <div className={styles.codeInfo}>
                                {contract.nSLOC}
                              </div>
                              <div className={styles.codeInfo}>
                                {contract.complexityScore}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
              </>
            }
          />
        </div>
      </>
    );
  }
);

export default CodeComplexity;
