import { auditCompaniesType } from "./reportDataTypes";

export function getType(type: auditCompaniesType) {
  const newType = type.toString();
  if (newType === "ContestPlatform") {
    return "Contest Platform";
  } else if (newType === "IndependantResearcher") {
    return "Independent Researcher";
  } else {
    return newType;
  }
}
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

export function containsUpperCase(str: string) {
  return /[A-Z]/.test(str);
}

export function isPasswordValid(inputString: string) {
  return (
    !inputString.includes(" ") &&
    inputString.length > 7 &&
    containsUpperCase(inputString)
  );
}

export function isValidEmail(email: string) {
  var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function formatNumberShort(num: number): string {
  if (num > 999999999) {
    return num.toLocaleString();
  } else if (num > 999999) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num > 999) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}
export function getSeverityColor(severity: string) {
  if (severity.toLowerCase() === "high") {
    return "rgba(255, 99, 132, 0.2)";
  } else if (severity.toLowerCase() === "medium") {
    return "rgba(255, 159, 64, 0.2)";
  } else if (severity.toLowerCase() === "low") {
    return "rgba(255, 206, 86, 0.2)";
  } else {
    return "rgba(75, 192, 192, 0.2)";
  }
}
export function getSeverityBorderColor(severity: string) {
  if (severity.toLowerCase() === "high") {
    return "rgba(255, 99, 132, 1)";
  } else if (severity.toLowerCase() === "medium") {
    return "rgba(255, 159, 64, 1)";
  } else if (severity.toLowerCase() === "low") {
    return "rgba(255, 206, 86, 1)";
  } else {
    return "rgba(75, 192, 192, 1)";
  }
}