import styles from "./VulnerabilityReportPage.module.css";

import { useEffect, useState } from "react";
import { sendRequest } from "../../utils/apiRequest";
import { userData } from "../../utils/severRequestTypes";
import ErrorContainer from "../../components/Reusable/ErrorContainer";
import { FaRegClipboard } from "react-icons/fa";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {oneDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getSeverityBorderColor, getSeverityColor } from "../../utils/textformUtils";
import { getVulnerabilityURL } from "../../api";
import { useSearchParams } from "react-router-dom";
import { vulnerabilityReport } from "../../utils/vulnerabilityReportDataType";

interface IProtocolPage {
  user?: userData | null;
}

const cleanMarkdown = (rawText: string): string => {
  const rawTextUnescaped = unescapeUnicode(rawText);
  const lines = rawTextUnescaped.split('\n');
  const formattedLines = lines.map(line => {
    return line.replace(/^[\s\t]{1,3}/, '');
  });
  return formattedLines.join('\n');
};
function unescapeUnicode(escaped: string): string {
  return escaped.replace(/\\u([\dA-F]{4})/gi, (_, g1) =>
    String.fromCharCode(parseInt(g1, 16))
  );
}
const VulnerabilityReportPage: React.FC<IProtocolPage> = ({ user }) => {
  const [vulnerabilityReport,setVulnerabilityReport ] =
    useState<vulnerabilityReport | null>(null);
    const [queryParameters] = useSearchParams();
    
    const reportName = queryParameters.get("name") ?? "";

    useEffect(() => {
      const fetchProtocolReport = async () => {
        await sendRequest(
          getVulnerabilityURL,
          "Error while getting report",
          undefined,
          reportName,
          setVulnerabilityReport
        );
      };
  
      if (user && reportName.length > 0) fetchProtocolReport();
    }, [user]);
  
  if (!user) {
    return (
      <ErrorContainer content={<>Please Login to view Vulnerability Data</>} />
    );
  }
  return (
    <div className={styles.vulnerabilityReport}>
      <div className={styles.vulnerabilityReport__container}>
        <div className={styles.vulnerabilityReport__main}>
        {vulnerabilityReport !== null ? <>
          <div className={styles.splitContainer}>
            <div style={{display:"flex",flexDirection:"column"}}>
              <img
                className={styles.logo}
                alt=""
                src={vulnerabilityReport.logoURL}
              />
              <div
                style={{
                  background: getSeverityColor(vulnerabilityReport.severity),
                  borderColor: getSeverityBorderColor(
                    vulnerabilityReport.severity
                  ),
                }}
                className={styles.vulLevel}
              >
                {vulnerabilityReport.severity.toLocaleUpperCase()}
              </div>
            </div>
            <div>
              <h1 className={styles.vulnerabilityReport__title}>
                {unescapeUnicode(vulnerabilityReport.title)}
              </h1>
              <p className={styles.vulnerabilityReport__text}>
                <FaRegClipboard />
                Author(s): {vulnerabilityReport.user} {vulnerabilityReport.user.length > 0 ? "-" : ""} {vulnerabilityReport.company}
              </p>
              <p className={styles.vulnerabilityReport__text}>
                <FaRegClipboard />
                Protocol: {vulnerabilityReport.protocol}
              </p>
              <p className={styles.vulnerabilityReport__text}>
                <FaRegClipboard />
                Category: {vulnerabilityReport.category}
              </p>
            </div>
          </div>
          <Markdown
            className={styles.line}
            remarkPlugins={[gfm]}
            children={cleanMarkdown(vulnerabilityReport.raw)}
            components={{
              code({ node, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return match ? (
                  <SyntaxHighlighter
                    style={oneDark}
                    language={match[1]}
                    PreTag="div"
                  >
                    {String(children).replace(/\n$/, "")}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          /> </>
        : <></>}
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityReportPage;
