export const themeProperties = {
  "--background-color": "sides",
  "--font-color": "text",
  "--main-background": "main",
  "--sidemenu-font-color": "sideMenuFont",
  "--backgound-color-dark": "footerColor",
  "--ProtocolPage-text-color": "text",
  "--ProtocolPage-border-color": "text",
  "--lightborder-color": "text",
  "--side-menu-hover": "sideMenuHover",
  "--drop-down-menu": "dropDownMenuColor",
};

export const cssVariables = {
  "--bg-color": (isDark: boolean) => (isDark ? "#252935" : "#f5f5f5"),
  "--text-color": (isDark: boolean) => (isDark ? "#f0f0f0" : "#333333"),
  "--container-bg": (isDark: boolean) =>
    isDark ? "rgba(36, 36, 53, 0.8)" : "#ffffff",
  "--border-color": (isDark: boolean) =>
    isDark ? "rgba(255, 255, 255, 0.12)" : "#e0e0e0",
  "--button-bg": "#6e5ee2",
  "--button-text": "#ffffff",
  "--button-hover": "#5d4ed1",
  "--secondary-text": (isDark: boolean) => (isDark ? "#8e8e8e" : "#666666"),
  "--container-hover-bg": (isDark: boolean) =>
    isDark ? "rgba(40, 40, 60, 0.9)" : "rgba(245, 245, 245, 0.9)",
  "--container-hover-border": (isDark: boolean) =>
    isDark ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.15)",
  "--gradient-color": (isDark: boolean) =>
    isDark ? "rgba(255, 215, 0, 0.08)" : "rgba(110, 94, 226, 0.08)",
};
