import styles from "./FeaturedAuditCompany.module.css";
import {
  auditCompanies,
  auditCompaniesType,
} from "../../utils/reportDataTypes";
import { getAuditCompaniesByArrayURL } from "../../api";
import { useEffect, useState } from "react";
import BasicToolTip from "../Reusable/BasicToolTip";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { sendRequest } from "../../utils/apiRequest";
import { Link } from "react-router-dom";
import inputControlStyles from "../../styles/common/inputControls.module.css";

const FeaturedAuditCompany = () => {
  const [featuredCompany, setFeaturedCompany] = useState<auditCompanies[]>([
    {
      name: "tmp",
      logoURL: "/SCASLogo.png",
      link: "",
      type: auditCompaniesType.ContestPlatform,
      id: 100,
    },
  ]);

  useEffect(() => {
    const fetchFeaturedAuditCompany = async () => {
      await sendGetFeaturedCompanyRequest(110);
    };
    fetchFeaturedAuditCompany();
  }, []);

  const sendGetFeaturedCompanyRequest = async (id: number) => {
    const sendData = [id];
    await sendRequest(
      getAuditCompaniesByArrayURL,
      "Error getting feature company",
      undefined,
      sendData,
      setFeaturedCompany,
    );
  };

  return (
    <div className={styles.featureBox}>
      <h3>Start using SCAS Pro </h3>
      <div className={styles.featuredBoxInner}>
        <div className={styles.headerRow}>
  
          <div className={styles.headerText}>
            <h4>
              SC Audit Studio Pro
            </h4>
            <p>Purchase SCAS Pro for 6 months</p>
          </div>
        </div>
        <div className={styles.companyTypeRow}>
          <h4>Unlock All Features</h4>
          <p>
          Unlock more API calls, use exclusive pro features and more
          </p>
        </div>
        <div className={styles.companyTypeRow}>
          <h4>Increase Audit ROI</h4>
          <p>
          Save time on audits by using all features of SCAS
          </p>
        </div>
        <div className={styles.trustedBy}>
          <h4>SCAS Pro Features
          <BasicToolTip
                icon={<IoShieldCheckmarkOutline size={20} />}
                text={<p>SCAS Verified</p>}
              />
          </h4>
        </div>
        <Link to={"https://scauditstudio.de/audits/"} className={inputControlStyles["button-link"]}>
              <button
                className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
              >
                Get Started
              </button>
        </Link>
      </div>
    </div>
  );
};
export default FeaturedAuditCompany;
