import { MdErrorOutline } from "react-icons/md";
import styles from "./ErrorContainer.module.css";

interface IErrorContainer {
  content: JSX.Element;
}

const ErrorContainer: React.FC<IErrorContainer> = ({ content }) => {
  return (
    <div className={styles["error-container"]}>
      <div className={styles["error-box"]}>
        <MdErrorOutline className={styles["icon"]} size={25} />
        <p>{content}</p>
      </div>
    </div>
  );
};

export default ErrorContainer;
