import styles from "./VulnerbilitesPage.module.css";
import { useEffect, useState } from "react";
import SearchBar from "../../components/Reusable/SearchBar";
import {
  getSeverityBorderColor,
  getSeverityColor,
} from "../../utils/textformUtils";
import { vulnerabilityReport } from "../../utils/vulnerabilityReportDataType";
import { ExternalLink } from "lucide-react";
import { useTheme } from "../../hooks/useTheme";
import { sendRequest } from "../../utils/apiRequest";
import { getVulnerabilityRangeURL } from "../../api";
import { userData } from "../../utils/severRequestTypes";
import ErrorContainer from "../../components/Reusable/ErrorContainer";
import { Link } from "react-router-dom";

interface IVulnerabilitesPage {
  user?: userData | null;
}
const VulnerabilitesPage: React.FC<IVulnerabilitesPage> = ({ user }) => {
  const [allVulnerbilites, setAllVulnerbilites] = useState<
    vulnerabilityReport[] | null
  >(null);
  const [auditCompanySearch, setAuditCompanySearch] = useState("");
  const isDarkTheme = useTheme();
  const [buttonState, setButtonState] = useState(1);
  const itemsPerPage = 50;

  const iconColor = isDarkTheme
    ? "rgba(255, 255, 255, 0.87)"
    : "rgba(0, 0, 0, 0.87)";
  const handleAuditCompanySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuditCompanySearch(e.target.value);
  };
  const handleButtonClick = (i: number) => {
    setAuditCompanySearch("");
    setButtonState(i);
  };
  useEffect(() => {
    const fetchProtocolReport = async () => {
      await sendRequest(
        getVulnerabilityRangeURL,
        "Error while getting report",
        undefined,
        [0, 20],
        setAllVulnerbilites
      );
    };

    if (user) fetchProtocolReport();
  }, [user]);

  if (!user) {
    return <ErrorContainer content={<>Please Login to view Protocol Data</>} />;
  }

  return (
    <div className={styles.publications}>
      <div className={styles.container}>
        <div className={styles.reportContainer}>
          <h3>Vulnerbility Reports </h3>
          <SearchBar
            value={auditCompanySearch}
            onChange={handleAuditCompanySearchChange}
            placeholder="Search for Vulnerbility Reports"
          />

          <ul className={styles.CompanieList}>
            <li className={styles.row}>
              <p>Company</p>
              <p className={styles.companyType}>Category</p>
              <p className={styles.companyType}>Share</p>
            </li>
            {allVulnerbilites !== null ? (
              allVulnerbilites
                .slice((buttonState - 1) * itemsPerPage, buttonState * itemsPerPage)
                .map((report) => (
                  <li key={report.title} className={styles.row}>
                    <Link
                      to={`/vulnerability?name=${report.link}`}
                      className={styles.rowNameContainer}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <img
                          alt=""
                          src={report.logoURL}
                          className={styles.image}
                        />
                        <div
                          style={{
                            background: getSeverityColor(report.severity),
                            borderColor: getSeverityBorderColor(
                              report.severity
                            ),
                          }}
                          className={styles.vulLevel}
                        >
                          {report.severity.toLocaleUpperCase()}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <p>{report.title}</p>
                        <p className={styles.vulnerabilityReport__description}>
                          Author(s): {report.user}{" "}
                          {report.user.length > 0 ? "-" : ""} {report.company}
                        </p>
                        <p className={styles.vulnerabilityReport__description}>
                          Protocol: {report.protocol}
                        </p>
                      </div>
                    </Link>
                    <p className={styles.companyType}>{report.category}</p>
                    <p className={styles.companyType}>
                      <ExternalLink size={18} color={iconColor} />
                    </p>
                  </li>
                ))
            ) : (
              <></>
            )}
          </ul>
          {allVulnerbilites !== null ? (
            <div className={styles.buttonsContainer}>
              {Array.from(
                {
                  length:
                    Math.floor(
                      (Math.max(0, allVulnerbilites.length) - 1) / itemsPerPage
                    ) + 1,
                },
                (value, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => handleButtonClick(index + 1)}
                      style={
                        buttonState === index + 1 ? { color: "#6E5EE2" } : {}
                      }
                    >
                      {index + 1}
                    </button>
                  );
                }
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default VulnerabilitesPage;
