import styles from "./AllProtocolsPage.module.css";
import { getReportPreviewsFilteredURL, getReportPreviewsURL } from "../../api";
import { useEffect, useState } from "react";
import RatingBar from "../../components/RatingBar";
import { Link } from "react-router-dom";
import SearchBar from "../../components/Reusable/SearchBar";
import { sendRequest } from "../../utils/apiRequest";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import FilterSelect from "../../components/Reusable/FilterSelect";
import { protocolPreview } from "../../utils/reportDataTypes";
import { formatDate, formatNumberShort } from "../../utils/textformUtils";

interface IAllProtocolsPage {
  protocolPreviews: protocolPreview[] | null;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}
type filterOption = {
  lable: string;
  key: string;
  format: string,
};
const AllProtocolsPage: React.FC<IAllProtocolsPage> = ({
  protocolPreviews,
  setCurrentProtocolID,
}) => {
  const [allProtocols, setAllProtocols] = useState<any[] | null>(
    protocolPreviews
  );
  const [protocolSearch, setProtocolSearch] = useState("");
  const filterOptions: filterOption[] = [
    { lable: "Audit Amount", key: "auditAmount",format:"number" },
    { lable: "Code Lines", key: "linesOfCode" ,format:"number"},
    { lable: "Bug Bounty Amount", key: "bugBountyAmount" ,format:"currency"},
    { lable: "Vul. Report Amount", key: "reportAmount",format:"number" },
    { lable: "Decentralization Score", key: "centralizationScore",format:"number" },
    { lable: "Last Codebase Modification", key: "lastCodeBaseModification",format:"date" },
  ];
  const [filters, setFilters] = useState<filterOption[]>([{ lable: "Audit Amount", key: "auditAmount",format:"number" }]);
  
  const stringFormatData = (input: string,format: string) => {
    if(format === "date"){
      return formatDate(input);
    }
    if(format === "currency"){
      return formatNumberShort(Number.parseInt(input));
    }
    return input;
  }

  const handleProtocolSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProtocolSearch(e.target.value);
  };

  const handleFilterChange =
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (filters.find((filter) => filter.lable === event.target.value)) {
        return;
      }
      filters[index] = filterOptions.find(
        (filter) => filter.lable === event.target.value
      ) ?? { lable: "Audit Amount", key: "auditAmount",format:"number" };
      setFilters([...filters]);
    };

  const addFilter = () => {
    if (filters.length === 3) {
      return;
    }
    setFilters([...filters, filterOptions[filters.length]]);
  };

  const sendSearchRequest = async () => {
    const keys = filters.map((filter) => filter.key);
    await sendRequest(
      getReportPreviewsFilteredURL,
      "Error getting Protocols",
      undefined,
      keys,
      setAllProtocols
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await sendRequest(
        getReportPreviewsURL,
        "Error getting Protocols",
        undefined,
        undefined,
        setAllProtocols
      );
    };
    if (!protocolPreviews) {
      fetchData();
    }
  }, [protocolPreviews]);

  return (
    <div className={styles.container}>
      <div className={styles.reportContainer}>
        <div className={styles.header}>
          <h3>Protocols</h3>
        </div>
        <div className={styles.Search}>
          <SearchBar
            value={protocolSearch}
            onChange={handleProtocolSearchChange}
            placeholder="Search for protocol"
          />
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            onClick={sendSearchRequest}
          >
            Search
          </button>
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
            onClick={() => setFilters([{ lable: "Audit Amount", key: "auditAmount",format:"number" }])}
          >
            Clear
          </button>
        </div>
        <div className={styles.filerRow}>
          {filters.map((filter, index) => (
            <FilterSelect
              options={filterOptions}
              value={filter.lable}
              curValue="lable"
              onChange={handleFilterChange(index)}
              display="lable"
            />
          ))}
          <button
            className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
            onClick={() => addFilter()}
            disabled={filters.length === 3}
          >
            + Add filter
          </button>
        </div>
        <ul className={styles.CompanieList}>
          <li className={styles.row}>
            <p style={{textAlign:"left"}}>Protocol</p>
            {filters.map((filter) => (
              <>
                {filter !== undefined ? (
                  allProtocols ? (
                    allProtocols[0][filter.key] !== undefined ? (
                      <p>{filter.lable}</p>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </>
            ))}
            <p>Protocol Rating</p>
          </li>
          {allProtocols ? (
            allProtocols
              .filter((protocol) =>
                protocol.name
                  .toLowerCase()
                  .startsWith(protocolSearch.toLowerCase())
              )
              .map((protocol) => (
                <Link
                  onClick={() => setCurrentProtocolID(protocol.rid)}
                  to={`/protocol/${protocol.name}`}
                  key={protocol.name}
                  className={styles.row}
                >
                  <div className={styles.rowNameContainer}>
                    <img
                      alt=""
                      src={protocol.logoURL}
                      className={styles.image}
                    />
                    <p style={{textAlign:"left"}}>{protocol.name}</p>
                  </div>
                  {filters.map((filter) => (
                    <>
                      {filter !== undefined ? (
                        protocol[filter.key] !== undefined ? (
                          <p>{stringFormatData(protocol[filter.key],filter.format)}</p>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  <div className={styles.rating}>
                    <RatingBar
                      rating={protocol.score}
                      color1={"#FF6384"}
                      color2={"#FFCE56"}
                      color3={"#4BC0C0"}
                    />
                  </div>
                </Link>
              ))
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AllProtocolsPage;
