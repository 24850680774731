import { useEffect, useState } from "react";
import styles from "./PublishPage.module.css";
import { userData } from "../../utils/severRequestTypes";

import {
  getReportPreviewsPublishedByUserURL,
  getReportPreviewsFromAllUnderreviewURL,
  getReportPreviewsFromUserUnderreviewURL,
  getReportPreviewsEditedURL,
} from "../../api";

import { protocolPreview } from "../../utils/reportDataTypes";
import { sendRequest } from "../../utils/apiRequest";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import SearchBar from "../../components/Reusable/SearchBar";
import SideMeunPublishMainPage from "./SideMenu";
import { MdGppGood } from "react-icons/md";
import ProtocolList, { protocolState } from "./ProtocolList";

interface IPublishPage {
  user: userData | null;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const PublishPage: React.FC<IPublishPage> = ({
  user,
  setCurrentProtocolID,
}) => {
  const [allCreatedProtocols, setAllCreatedProtocols] = useState<
    protocolPreview[] | null
  >(null);
  const [allUnderReviewProtocols, setAllUnderReviewProtocols] = useState<
    protocolPreview[] | null
  >(null);
  const [allPublishedProtocols, setAllPublishedProtocols] = useState<
    protocolPreview[] | null
  >(null);
  const [confirmPublish, setConfirmPublish] = useState<number>(0);
  const [confirmDelete, setConfirmDelete] = useState<number>(0);

  const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
  const [requestSend, setRequestSend] = useState<boolean>(false);
  useEffect(() => {
    if (
      requestSend === true &&
      (allCreatedProtocols === null || !Array.isArray(allCreatedProtocols)) &&
      (allUnderReviewProtocols === null ||
        !Array.isArray(allUnderReviewProtocols)) &&
      (allPublishedProtocols === null ||
        !Array.isArray(allUnderReviewProtocols))
    ) {
      setShowInfoBox(true);
    } else {
      setShowInfoBox(false);
    }
  }, [requestSend]);

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      await sendRequest(
        getReportPreviewsEditedURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllCreatedProtocols
      );
      await sendRequest(
        user?.isAdmin
          ? getReportPreviewsFromAllUnderreviewURL
          : getReportPreviewsFromUserUnderreviewURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllUnderReviewProtocols
      );
      await sendRequest(
        getReportPreviewsPublishedByUserURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllPublishedProtocols
      );
      setRequestSend(true);
    };
    fetchProtocolPreviews();
  }, [user?.isAdmin]);

  if (user !== null) {
    return (
      <div className={styles.container}>
        <SideMeunPublishMainPage isAdmin={user.isAdmin} />
        <div className={styles.containerInner} style={{ width: "100%" }}>
          <div className={styles.header}>
            <b>Edit Protocol Entries</b>
            <div className={styles.Search}>
              <SearchBar
                value=""
                onChange={() => {}}
                placeholder="Search entries..."
              />
              <button
                className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
              >
                Search
              </button>
            </div>
          </div>
          <ul className={styles.ReportList}>
            {allCreatedProtocols !== null &&
            Array.isArray(allCreatedProtocols) ? (
              <ProtocolList
                isAdmin={user.isAdmin}
                state={protocolState.Created}
                allprotocols={allCreatedProtocols}
                confirmPublish={confirmPublish}
                confirmDelete={confirmDelete}
                setConfirmPublish={setConfirmPublish}
                setConfirmDelete={setConfirmDelete}
                setCurrentProtocolID={setCurrentProtocolID}
              />
            ) : (
              <></>
            )}
            {allUnderReviewProtocols !== null &&
            Array.isArray(allUnderReviewProtocols) ? (
              <ProtocolList
                isAdmin={user.isAdmin}
                state={protocolState.Underreview}
                allprotocols={allUnderReviewProtocols}
                confirmPublish={confirmPublish}
                confirmDelete={confirmDelete}
                setConfirmPublish={setConfirmPublish}
                setConfirmDelete={setConfirmDelete}
                setCurrentProtocolID={setCurrentProtocolID}
              />
            ) : (
              <></>
            )}
            {allPublishedProtocols !== null &&
            Array.isArray(allPublishedProtocols) ? (
              <ProtocolList
                isAdmin={user.isAdmin}
                state={protocolState.Published}
                allprotocols={allPublishedProtocols}
                confirmPublish={confirmPublish}
                confirmDelete={confirmDelete}
                setConfirmPublish={setConfirmPublish}
                setConfirmDelete={setConfirmDelete}
                setCurrentProtocolID={setCurrentProtocolID}
              />
            ) : (
              <></>
            )}
          </ul>
          {showInfoBox ? (
            <div className={styles.alertContainer}>
              <div className={styles.icon}>
                <MdGppGood size={35} />
              </div>
              <p>
                Submit Reports to get access to SCAS Pro. Learn more about properly
                submitting reports in our documentation.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  } else {
    return <>Error Please Login</>;
  }
};

export default PublishPage;
